import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="max-w-screen-md mx-auto pb-5">
      <Link to="/">
        <h1 className="text-3xl font-light mt-5">AugmityLabs
        </h1>
      </Link>

      <h2 className="text-xl text-gray-600 my-4">
        Projects
      </h2>

      <ul className="marker:text-sky-400 list-disc pl-5 space-y-3 text-slate-500">
     
        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://daily-photo.web.app/" target="_blank" rel="noreferrer">https://daily-photo.web.app</a>            
          </div>
          <div className="">
            A visual photo diary. Written in Flutter for Android/iOS/web
          </div>

          <a href="https://about-daily-photo.web.app/" target="_blank" rel="noreferrer" className='text-sky-400 text-sm'>(project details)</a>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://a-million-miles-in-a-thousand-years.netlify.app/" target="_blank" rel="noreferrer">https://a-million-miles-in-a-thousand-years.netlify.app</a>
          </div>
          <div className="">
            Shows visited places on a map. Written in Angular.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://flutter-app-viewer.netlify.app" target="_blank" rel="noreferrer">https://flutter-app-viewer.netlify.app</a>
          </div>
          <div className="">
            Demo of the iOS/Android/web applications written in Flutter.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://identity3.web.app/" target="_blank" rel="noreferrer">https://identity3.web.app</a>            
          </div>
          <div className="">
            Identity3 app - decentralized identity proof-of-concept (iOS/Android/web, written in Flutter)
          </div>

          <Link to="/project-identity3" className='text-sky-400 text-sm'>(project details)</Link>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://stuff-share.web.app/" target="_blank" rel="noreferrer">https://stuff-share.web.app</a>
          </div>
          <div className="">
            stuff-share app (iOS/Android/web, written in Flutter)
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://storage3.netlify.app/" target="_blank" rel="noreferrer">https://storage3.netlify.app</a>
          </div>
          <div className="">
            An original version of the storage viewer app, written in Angular. The newer version is written in Flutter, so
            could be compiled for any environment (iOS/Android/web/Windows/MacOs/Linux)
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://photos-from-the-road.netlify.app/" target="_blank" rel="noreferrer">https://photos-from-the-road.netlify.app</a>
          </div>
          <div className="">
            Photo gallery app written in Angular.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://canadian-historical-weather-data.netlify.app" target="_blank" rel="noreferrer">https://canadian-historical-weather-data.netlify.app</a>
          </div>
          <div className="">
            React app that displays all the weather stations in Canada and their historic data.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg"
              href="https://volunteer-app-react.netlify.app/" target="_blank" rel="noreferrer">https://volunteer-app-react.netlify.app</a>
          </div>
          <div className="">
            Volunteer management app (people, locations, tasks, shifts). Written in React.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://travel-plans.netlify.app/map" target="_blank" rel="noreferrer">https://travel-plans.netlify.app/map</a>
          </div>
          <div className="">
            Inspiration app. Travel plans.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://garden-ai.netlify.app/" target="_blank" rel="noreferrer">https://garden-ai.netlify.app</a>
          </div>
          <div className="">
            IoT device (ESP-32 + light and humidity sensors) sends data to the cloud.
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://myinfo.augmity.com/" target="_blank" rel="noreferrer">https://myinfo.augmity.com</a>
          </div>
          <div className="">
            QR codes to easily share basic data (first/last name, email, address, etc.)
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://robbojs.augmity.com/" target="_blank" rel="noreferrer">https://robbojs.augmity.com</a>
          </div>
          <div className="">
            An unfinished attempt to recreate "Robbo" game with JavaScript (more complete than Unity version though)
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://robbo.augmity.com/" target="_blank" rel="noreferrer">https://robbo.augmity.com</a>
          </div>
          <div className="">
            An unfinished attempt to recreate "Robbo" game with Unity
          </div>
        </li>

        <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://kawaii-bot.netlify.app/" target="_blank" rel="noreferrer">https://kawaii-bot.netlify.app</a>
          </div>
          <div className="">
            Just cute 🌸
          </div>
        </li>

        {/* <li className="mb-2">
          <div>
            <a className="text-emerald-500 text-lg" href="https://a-circle-vampire.augmity.com/" target="_blank" rel="noreferrer">https://a-circle-vampire.augmity.com</a>
          </div>
          <div className="">
            A Circle Vampire adventures.
          </div>
        </li> */}

        {/* https://calendar.augmity.com/
        https://flutter-identity.augmity.com/
		    https://storage3.augmity.com/ */}

      </ul>

      <h2 className="text-xl text-gray-600 my-4">
        Links
      </h2>

      <div className="mb-2 ml-3 text-gray-700">
        <div>
          Twitter:
          <a className="text-emerald-500 text-lg ml-1" href="https://twitter.com/augmitylabs" target="_blank" rel="noreferrer">@augmityLabs</a>
        </div>
      </div>


    </div>
  );
}

export default Home;
