import { Link } from 'react-router-dom';

function ProjectIdentity() {
  return (
    <div className="max-w-screen-md mx-auto pb-5">
      <Link to="/">
        <h1 className="text-3xl font-light mt-5">AugmityLabs
        </h1>
      </Link>

      <h2 className="text-xl text-gray-600 my-4">
        Identity3
      </h2>

      <div className="mb-2 text-gray-700">
        Demo:
        <a className="text-emerald-500 text-base ml-1" href="https://identity3.web.app/" target="_blank" rel="noreferrer">https://identity3.web.app/</a>
      </div>

      <div>
        ...
      </div>

    </div>
  );
}

export default ProjectIdentity;
